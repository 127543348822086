import { AfterViewInit, Directive, Input } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

import { PearlMenuComponent } from './pearl-menu.component';

@Directive({
  standalone: true,
  selector: '[pearlMenuTriggerFor]',
  exportAs: 'pearlMenuTrigger',
})
export class PearlMenuTriggerForDirective extends MatMenuTrigger implements AfterViewInit {
  @Input('pearlMenuTriggerFor')
  pearlMenu: PearlMenuComponent;

  ngAfterViewInit(): void {
    super.menu = this.pearlMenu.$matMenu;
  }

  public toggle(): void {
    const isOpened = this.menuOpen;
    this.toggleMenu();
    if (isOpened) return;
    /**
     * By default the first element of the menu is focused (so, highlighted) when opened programmatically.
     * We don't want that.
     */
    setTimeout(() => {
      (document.activeElement as HTMLElement).blur();
    }, 0);
  }
}
