import { NgClass, UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild, booleanAttribute, computed, inject,
  input } from '@angular/core';
import { MatMenu, MatMenuItem, MenuPositionX, MenuPositionY } from '@angular/material/menu';
import { MatTooltip, TooltipPosition } from '@angular/material/tooltip';
import { Params } from '@angular/router';

import { ButtonMode, PearlButtonComponent } from '../buttons/pearl-button.component';
import { PearlIconComponent } from '../icons/pearl-icon.component';
import { PearlIcon, PearlIconSize } from '../icons/icons';
import { TrackedActionType } from '../../../product-analytics/product-analytics.types';
import { ProductAnalyticsService } from '../../../product-analytics/product-analytics.service';

export class PearlMenuCategory {
  label?: string;
  items: PearlMenuItem[];
}

export class PearlMenuItem {
  label: string;
  supportingText?: string;
  disabled?: boolean;
  tooltip?: {
    message: string;
    position: TooltipPosition;
  };
  leadingIcon?: {
    icon: PearlIcon;
    mode?: ButtonMode;
  };
  trailingIcon?: PearlIcon;
  leadingImageUrl?: string;
  active?: boolean;

  /** Whether to include a divider after the items */
  divider?: boolean;

  /** Custom click handler */
  onClick?: (event: MouseEvent, item: PearlMenuItem) => unknown;
  trackedAction?: TrackedActionType;
  trackedParams?: Params;
}

@Component({
  standalone: true,
  selector: 'pearl-menu',
  templateUrl: './pearl-menu.component.html',
  styleUrls: ['./pearl-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatMenu,
    MatMenuItem,
    MatTooltip,
    NgClass,
    PearlButtonComponent,
    PearlIconComponent,
    UpperCasePipe,
  ],
})
export class PearlMenuComponent {
  public readonly categories = input.required<PearlMenuCategory[]>();
  public readonly compact = input(false, { transform: booleanAttribute });
  public readonly limitHeight = input(false, { transform: booleanAttribute });
  public readonly xPosition = input<MenuPositionX>('after');
  public readonly yPosition = input<MenuPositionY>('below');
  public readonly panelWidth = input<number>();
  public readonly hasBackdrop = input<boolean>(true);

  protected readonly iconSize = computed<PearlIconSize>(() => {
    return this.compact() ? 20 : 24;
  });

  protected menuPanelClass = computed<string>(() => {
    return `${this.compact() ? 'compact' : ''} ${this.limitHeight() ? 'limit-height' : ''} ${
      this.panelWidth() ? 'custom-width' : ''
    }`;
  });
  private readonly productAnalyticsService = inject(ProductAnalyticsService);

  @ViewChild(MatMenu)
  $matMenu: MatMenu;

  protected onPearlMenuItemClick(event: MouseEvent, pearlMenuItem: PearlMenuItem): void {
    if (pearlMenuItem.disabled || typeof pearlMenuItem.onClick !== 'function') return;

    if (pearlMenuItem.trackedAction) {
      this.productAnalyticsService.trackAction(pearlMenuItem.trackedAction, pearlMenuItem.trackedParams ?? {});
    }
    pearlMenuItem.onClick(event, pearlMenuItem);
  }
}
